import React from 'react';
import styles from './AboutUs.module.css';
import AOS from 'aos'; // Import AOS library
import 'aos/dist/aos.css'; // Import AOS CSS for animations
import { Card, Typography } from 'antd';


const { Title, Text } = Typography;

const textStyle = {
    fontSize: '1.2em',
    lineHeight: '1.6',
    fontFamily: "EB Garamond",
    color: '#172c47',
    maxWidth: '600px',
    margin: '0 auto'
  };

const titleStyle = {
    fontFamily: 'Sephir', // Change font here
    marginBottom: '40px',
    fontWeight: '600',
    fontSize: '5em',
    fontOpticalSizing: 'auto',
    color: '#172c47'
  };

const AboutUs = () => {
  const features = [
    { icon: '🌟', title: 'Luxury Redefined', description: 'Setting new standards in luxury apparel' },
    { icon: '🕰️', title: 'Timeless Elegance', description: 'Crafting garments that empower and enhance lifestyles' },
    { icon: '🧵', title: 'Exceptional Craftsmanship', description: 'Dedicated to excellence in every stitch' },
    { icon: '🤝', title: 'Ethical Integrity', description: 'Upholding high standards in all our practices' },
    { icon: '🌿', title: 'Sustainable Materials', description: 'Using eco-friendly fabrics for a better tomorrow' },
    { icon: '🎨', title: 'Exclusive Designs', description: 'Offering unique, limited-edition pieces' },
  ];
  React.useEffect(() => {
    AOS.init({ duration: 1000, once: false }); // Adjust duration and other options as needed
  }, []);

  return (
    <div className={styles.container}>
      <header className={styles.header} data-aos="fade-up"
     data-aos-delay="200">
        <div className={styles.headerContent}>
        <Title style={titleStyle}> OTTO ACKERMAN </Title>
          <p className={styles.subtitle} data-aos="fade-up"
     data-aos-delay="100">
            Redefining luxury apparel with a commitment to quality, comfort, and timeless style. 
            We craft garments that empower individuals to look and feel their best every day.
          </p>
        </div>
      </header>
      
        <section className={styles.featuresSection} data-aos="fade-up"
     >
            <div className={styles.featuresGrid}>
            {features.map((feature, index) => (
                <div key={index} className={styles.featureCard} data-aos="fade-up"
                data-aos-delay="200">
                <div className={styles.featureIcon} >{feature.icon}</div>
                <div className={styles.featureContent}>
                    <h3 className={styles.featureTitle} >{feature.title}</h3>
                    <p className={styles.featureDescription} >{feature.description}</p>
                </div>
                </div>
            ))}
            </div>
        </section>
    </div>
  );
};

export default AboutUs;