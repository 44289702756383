import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button, Alert, Pagination, Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { ShoppingCartOutlined, HeartOutlined } from '@ant-design/icons';
import styles from '../Casuals.module.css'; // Updated import
import FormalPage from './TshirtsPage'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { API_URL } from '../../contsants';


const { Meta } = Card;
const { Title, Text } = Typography;

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const pageSize = 8;

  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${API_URL}/companies/list-products/?company=1&category=t_shirts`);
        setProducts(response.data.results);
        setTotalProducts(response.data.results.length);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" />;
  }

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentProducts = products.slice(startIndex, endIndex);

  return (
    <div className={styles.productListContainer}>
      <div style={{marginBottom: '120px'}}>
        {/* <NavBar /> */}
      </div>
      <div style={{marginBottom: '100px'}}
        data-aos="fade-up"
        data-aos-delay="200" >
        <FormalPage />
      </div>
      <div data-aos="fade-up"
        data-aos-anchor-placement="top-bottom" data-aos-delay="600">
        <Title level={2} className={styles.productListTitle} style={{color: '#172c47', fontFamily: 'Garamond', fontWeight: '400'}}>
          Discover Our T-Shirt Collections
        </Title>
      </div>
      {products.length === 0 ? (
        <Alert message="No products found" type="info" />
      ) : (
        <>
          <div className={styles.productGrid}
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-delay="600">
            {currentProducts.map((product) => (
              <Link to={`/product/${product.id}`} key={product.id} className={styles.productCardWrapper}>
                <Card
                  hoverable
                  className={styles.productCard}
                  cover={
                    <div className={styles.productImageContainer}>
                      <img alt={product.name} src={product.image[0].image} className={styles.productImage} />
                      <div className={styles.productQuickActions}>
                        {/* <ShoppingCartOutlined className={styles.actionIcon} /> */}
                        <HeartOutlined className={styles.actionIcon} />
                      </div>
                    </div>
                  }
                >
                  <Meta
                    title={<Text strong className={styles.productName}>{product.name}</Text>}
                    description={
                      <div className={styles.productDetails}>
                        <Text className={styles.productPrice}>${product.price.toFixed(2)}</Text>
                        <Text className={styles.productFit}>Fit: {product.fit}</Text>
                      </div>
                    }
                  />
                </Card>
              </Link>
            ))}
          </div>
          <Pagination
            className={styles.customPagination}
            current={currentPage}
            total={totalProducts}
            pageSize={pageSize}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </>
      )}
      <div style={{marginTop: '100px'}}>

      </div>
    </div>
  );
};

export default ProductList;