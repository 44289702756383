import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { Alert, Tooltip, Image, message } from 'antd';
import { ShoppingOutlined, HeartOutlined } from '@ant-design/icons';
import './ProductDetails.css';
import NavBar from './navbar/Navbar';
import Footer from './Footer'
import { API_URL } from '../contsants';

const ProductDetails = () => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedSizeId, setSelectedSizeId] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const { id } = useParams();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${API_URL}/companies/get-product/${id}/`);
        const productData = response.data;
        setProduct(productData);
        setSelectedColor(productData.color[0]); // Assuming color is still an array of IDs
        if (productData.size && productData.size.length > 0) {
          setSelectedSize(productData.size[0].size__size_name);
          setSelectedSizeId(productData.size[0].id);
        }
        setMainImage(productData.image[0].image);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product:', error);
        setError(error.message);
        setLoading(false);
      } 
    };
    fetchProduct();
  }, [id]);

  const handleSizeSelect = (sizeId, sizeName) => {
    setSelectedSize(sizeName);
    setSelectedSizeId(sizeId);
  };

  const handleAddToCart = async () => {
    try {
      const response = await axios.post(`${API_URL}/companies/cart/`, {
        product: product.id,
        quantity: quantity,
        size_id: selectedSizeId
      });
      message.success('Product added to cart successfully!');
    } catch (error) {
      console.error('Error adding product to cart:', error);
      if (error.response && error.response.data && error.response.data.error) {
        message.error(`${error.response.data.error}`);
      } else {
        // If there is no error message from the backend, show a generic error
        message.error('Failed to add product to cart: Unknown error occurred');
      }
    }
  };


  const handleAddToWishlist = async () => {
    try {
      const response = await axios.post(`${API_URL}/companies/wishlist-items/`, {
        product: product.id,
      });
      message.success('Product added to wishlist successfully!');
    } catch (error) {
      console.error('Error adding product to wishlist:', error);
      if (error.response && error.response.data && error.response.data.error) {
        message.error(`${error.response.data.error}`);
      } else {
        // If there is no error message from the backend, show a generic error
        message.error('Failed to add product to cart: Unknown error occurred');
      }
    }
  };

  if (loading) return <div className="loading-spinner"></div>;
  if (error) return <Alert message="Error" description={error} type="error" />;
  if (!product) return <Alert message="Product not found" type="error" />;

  return (
    <div>
      <div className="product-details-container">
        <div style={{marginTop: '50px'}}>
          {/* <NavBar /> */}
        </div>
        <div className="product-details">
          <div className="product-gallery">
            <Image.PreviewGroup>
              <Image src={mainImage} alt={product.name} className="main-image" />
              <div className="thumbnail-container">
                {product.image.map((img, index) => (
                  <Image 
                    key={index} 
                    src={img.image} 
                    alt={`${product.name} - View ${index + 1}`} 
                    className={`thumbnail ${mainImage === img.image ? 'active' : ''}`}
                    onClick={() => setMainImage(img.image)}
                    preview={false}
                  />
                ))}
              </div>
            </Image.PreviewGroup>
          </div>
          <div className="product-info">
            <h1 className="product-name">{product.name}</h1>
            <div className="price-tag">${product.price}</div>
            <p className="fit">{product.fit}</p>
            
            <div className="options-container">
              <div className="color-options">
                <h3>Color: <span>{selectedColor}</span></h3>
                <div className="color-swatches">
                  {product.color.map(color => (
                    <Tooltip title={color} key={color}>
                      <div
                        className={`color-swatch ${selectedColor === color ? 'selected' : ''}`}
                        style={{ backgroundColor: color }}
                        onClick={() => setSelectedColor(color)}
                      ></div>
                    </Tooltip>
                  ))}
                </div>
              </div>
              
              {product.size && product.size.length > 0 && (
              <div className="size-options">
                <h3>Select Size: <span>{selectedSize}</span></h3>
                <div className="size-swatches">
                  {product.size.map(size => (
                    <div
                      key={size.id}
                      className={`size-swatch ${selectedSize === size.size__size_name ? 'selected' : ''}`}
                      onClick={() => handleSizeSelect(size.id, size.size__size_name)}
                    >
                      {size.size__size_name}
                    </div>
                  ))}
                </div>
              </div>
            )}
            </div>
            
            {/* <div className="quantity-selector">
              <h3>Quantity:</h3>
              <input
                type="number"
                min="1"
                value={quantity}
                onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value)))}
              />
            </div> */}
            
            <div className="product-actions">
              <button className="add-to-cart-btn" onClick={handleAddToCart}>
                <ShoppingOutlined /> Add to Bag
              </button>
              <button className="wishlist-btn" onClick={handleAddToWishlist}>
                <HeartOutlined />
              </button>
            </div>

            <div className="product-description">
              <h3>Description</h3>
              <p>{product.description || 'No description available'}</p>
            </div>
          </div>
        </div>
      </div>
      <div style={{marginTop: '80px'}}>
      </div>
    </div>
  );
};

export default ProductDetails;