import React from 'react';
import { Card, Typography } from 'antd';
import CasualsImg from './bestseller1.jpeg';

const { Title, Text } = Typography;

const ProductCard = ({ product }) => {
  const cardStyle = {
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    borderRadius: '10px',
    overflow: 'hidden',
    width: '100%',
    boxShadow: 'none',
    background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)'

  };

  const contentStyle = {
    display: 'flex',
    width: '100%',
  };

  const imageContainerStyle = {
    flex: '1',
    overflow: 'hidden',
    borderRadius: '10px',

  };

  const productImageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const textContentStyle = {
    flex: '1',
    padding: '32px',
    textAlign: 'center',
    fontFamily: '"Poppins", sans-serif', // Change font here
  };

  const titleStyle = {
    fontFamily: 'Garamond', // Change font here
    marginBottom: '40px',
    fontWeight: '400',
    fontSize: '50px',
    fontOpticalSizing: 'auto',
    color: '#172c47'
  };

  const textStyle = {
    fontSize: '18px',
    lineHeight: '1.5',
    fontFamily: "EB Garamond",
    color: '#172c47'


  };

  return (

    <Card style={cardStyle} hoverable={false}>
      <div style={contentStyle}>
        <div style={textContentStyle}>
          <Title level={1} style={titleStyle}>Best Sellers</Title>
          <Text style={textStyle}>
          Explore our Best Sellers collection, where fashion meets impeccable taste. Each piece is a testament to timeless style and unparalleled quality. Elevate your wardrobe with the favorites our customers can't get enough of

          </Text>
        </div>
        <div style={imageContainerStyle}>
          <img src={CasualsImg} alt='img' style={productImageStyle} />
        </div>
      </div>
    </Card>
  );
};

export default ProductCard;
