import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import OttoLogo from '../company_images/images/logoottoackerman.png';
import { API_URL } from '../../contsants';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const ResetContainer = styled.div`
  display: flex;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
`;

const LeftPanel = styled.div`
  flex: 1;
  background: linear-gradient(-45deg, #172c47, #1a3559, #21466b, #172c47);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 2rem;
  position: relative;
  overflow: hidden;
`;

const Quote = styled.h2`
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  margin-bottom: 2rem;
  z-index: 1;
`;

const Vector = styled.div`
  position: absolute;
  opacity: 0.1;
  font-size: 15rem;
  z-index: 0;
`;

const RightPanel = styled.div`
  flex: 1;
  background-color: #f8fafc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const ResetForm = styled.form`
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  color: #172c47;
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 2.5rem;
  text-align: center;
`;

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 2rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border: none;
  border-bottom: 2px solid #e0e6ed;
  background-color: transparent;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #172c47;
  }
`;

const Label = styled.label`
  position: absolute;
  left: 0;
  top: 1rem;
  color: #8194a7;
  transition: all 0.3s ease;
  pointer-events: none;

  ${Input}:focus ~ &,
  ${Input}:not(:placeholder-shown) ~ & {
    top: -1rem;
    font-size: 0.8rem;
    color: #172c47;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  background-color: #172c47;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(23, 44, 71, 0.1);

  &:hover {
    background-color: #2a4a77;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(23, 44, 71, 0.2);
  }
`;

const Error = styled.p`
  color: red;
  text-align: center;
  margin-top: 1rem;
`;

const Success = styled.p`
  color: green;
  text-align: center;
  margin-top: 1rem;
`;

const LogoStyle = {
  position: 'absolute',
  left: '75%',
  transform: 'translateX(-50%)',
  maxHeight: '80px',
  width: 'auto',
  display: 'block',
  transition: 'all 0.3s ease',
  top: '25%'
};

const ResetPasswordPage = () => {
    const { uid, token } = useParams();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post(`${API_URL}users/password-reset-confirm/`, {
                uid,
                token,
                new_password: newPassword,
            });
    
            if (response.status === 200) {
                setSuccess('Password reset successfully!');
                setError(null);
                setTimeout(() => {
                    navigate('/sign-in');
                }, 3000);
            }
            console.log('Errorrrrrrrrrrr', response)
        } catch (error) {
            let errorMessage = 'Failed to reset password. Please try again.';
            
            if (error.response && error.response.data && error.response.data.password) {
                try {
                    // Parse the error string for the password field
                    const parsedError = JSON.parse(error.response.data.password);
                    if (Array.isArray(parsedError) && parsedError.length > 0) {
                        errorMessage = parsedError[0];
                    } else {
                        errorMessage = parsedError.toString();
                    }
                } catch (e) {
                    // If parsing fails, use the error string as is
                    errorMessage = error.response.data.password;
                }
                
                // Remove any remaining square brackets and single quotes
                errorMessage = errorMessage.replace(/[\[\]']+/g, '');
            }

            setError(errorMessage);
            setSuccess(null);
        }
    };
    

    return (
        <ResetContainer>
            <LeftPanel>
                <Quote>"A new password is a fresh start."</Quote>
                <Vector style={{ top: '10%', left: '10%' }}>◢</Vector>
                <Vector style={{ bottom: '10%', right: '10%' }}>◣</Vector>
            </LeftPanel>
            <RightPanel>
                <ResetForm onSubmit={handleSubmit}>
                    <img src={OttoLogo} alt='logo' style={LogoStyle}/>
                    <Title>Reset Password</Title>
                    {error && <Error>{error}</Error>}
                    {success && <Success>{success}</Success>}
                    <InputGroup>
                        <Input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder=" "
                            required
                        />
                        <Label>New Password</Label>
                    </InputGroup>
                    <Button type="submit">Reset Password</Button>
                </ResetForm>
            </RightPanel>
        </ResetContainer>
    );
};

export default ResetPasswordPage;