import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Plus, Minus, Trash } from 'lucide-react'; // Added Trash icon
import axios from 'axios';
import { message } from 'antd';
import { API_URL } from '../../contsants';

const PageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;

  min-height: 100vh;
`;

const CartItemsContainer = styled.div`
  flex: 3;
  margin-right: 2rem;
  background-color: #fff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  margin-top: 80px;
`;

const CartTitle = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  color: #172c47;
  margin-bottom: 1.5rem;
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e0e0e0;
`;

const ItemImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 12px;
  margin-right: 1.5rem;
`;

const ItemDetails = styled.div`
  flex-grow: 1;
`;

const ItemName = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  color: #172c47;
`;

const ItemPrice = styled.span`
  color: #1a1a2e;
  font-weight: 700;
`;

const QuantityControl = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;

const QuantityButton = styled.button`
  background: none;
  border: none;
  color: #1a1a2e;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f0f0f5;
  }
`;

const Quantity = styled.span`
  font-weight: 600;
  margin: 0 1rem;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: #ff0000;
  margin-left: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    color: #cc0000;
  }
`;

const SummaryContainer = styled.div`
  flex: 1;
  background-color: #fff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 2rem;
  height: fit-content;
  margin-top: 80px;

`;

const SummaryTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  color: #172c47;
  margin-bottom: 1.5rem;
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const SummaryLabel = styled.span`
  color: #1a1a2e;
  font-weight: 600;
`;

const SummaryValue = styled.span`
  color: #172c47;
  font-weight: 700;
`;

const CheckoutButton = styled.button`
  background-color: #172c47;
  color: #efcf96;
  border: none;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 50px;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1.5rem;

  &:hover {
    background-color: #efcf96;
    color: #172c47;
  }
`;

const ShoppingCartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get(`${API_URL}/companies/cart/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCartItems(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCartItems();
  }, []);

  const updateCartQuantity = async (itemId, newQuantity, itemSize) => {
    try {
      const token = localStorage.getItem('accessToken');
      await axios.patch(`${API_URL}/companies/delete-update-cart/${itemId}/`, {
        quantity: newQuantity,
        size: itemSize
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCartItems(cartItems.map(item => 
        item.id === itemId ? { ...item, quantity: newQuantity } : item
      ));
    } catch (err) {
      console.error('Error updating cart item quantity:', err);
      if (err.response && err.response.data && err.response.data.error) {
        message.error(`${err.response.data.error}`);
      } else {
        // If there is no error message from the backend, show a generic error
        message.error('Failed to add product to cart: Unknown error occurred');
      }
    }
  };

  const removeCartItem = async (itemId) => {
    try {
      const token = localStorage.getItem('accessToken');
      await axios.delete(`${API_URL}/companies/delete-update-cart/${itemId}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCartItems(cartItems.filter(item => item.id !== itemId));
    } catch (err) {
      console.error('Error removing item from cart:', err);
      if (err.response && err.response.data && err.response.data.error) {
        message.error(`${err.response.data.error}`);
      } else {
        // If there is no error message from the backend, show a generic error
        message.error('Failed to add product to cart: Unknown error occurred');
      }
    }
  };

  const totalPrice = cartItems.reduce((sum, item) => sum + item.product.price * item.quantity, 0);

  return (
    <PageContainer>
      <CartItemsContainer>
        <CartTitle>Your Cart</CartTitle>
        {cartItems.length === 0 ? (
          <div>Your cart is empty</div>
        ) : (
          cartItems.map((item) => (
            <CartItem key={item.id}>
              <ItemImage src={`${API_URL}${item.product.image[0].image}`} />
              <ItemDetails>
                <ItemName>{item.product.name}</ItemName>
                <ItemPrice>${(item.product.price * item.quantity).toFixed(2)}</ItemPrice>
                <QuantityControl>
                  <QuantityButton onClick={() => updateCartQuantity(item.id, item.quantity - 1, item.size)}>
                    <Minus size={16} />
                  </QuantityButton>
                  <Quantity>{item.quantity}</Quantity>
                  <QuantityButton onClick={() => updateCartQuantity(item.id, item.quantity + 1, item.size)}>
                    <Plus size={16} />
                  </QuantityButton>
                </QuantityControl>
              </ItemDetails>
              <RemoveButton onClick={() => removeCartItem(item.id)}>
                <Trash size={20} />
              </RemoveButton>
            </CartItem>
          ))
        )}
      </CartItemsContainer>

      <SummaryContainer>
        <SummaryTitle>Order Summary</SummaryTitle>
        <SummaryRow>
          <SummaryLabel>Subtotal</SummaryLabel>
          <SummaryValue>${totalPrice.toFixed(2)}</SummaryValue>
        </SummaryRow>
        <SummaryRow>
          <SummaryLabel>Shipping</SummaryLabel>
          <SummaryValue>Free</SummaryValue>
        </SummaryRow>
        <SummaryRow>
          <SummaryLabel>Total</SummaryLabel>
          <SummaryValue>${totalPrice.toFixed(2)}</SummaryValue>
        </SummaryRow>
        <CheckoutButton>Proceed to Checkout</CheckoutButton>
      </SummaryContainer>
    </PageContainer>
  );
};

export default ShoppingCartPage;
