
import React from 'react';
import styles from './AboutUs.module.css';
import AOS from 'aos'; // Import AOS library
import 'aos/dist/aos.css'; // Import AOS CSS for animations
import { faRoad } from '@fortawesome/free-solid-svg-icons';
import { Card, Typography } from 'antd';

const { Title, Text } = Typography;


const AboutUs = () => {

  React.useEffect(() => {
    AOS.init({ duration: 1000, once: false }); // Adjust duration and other options as needed
  }, []);

  return (
    <div className={styles.container}>
    
      <section className={styles.brandValues} data-aos="fade-up"
     data-aos-delay="600">
        <div className={styles.brandValuesContent}>
          <h2 className={styles.brandValuesTitle}>Our Commitment</h2>
          <ul className={styles.valuesList}>
            <li className={styles.valueItem}>Quality, comfort, and style in every garment</li>
            <li className={styles.valueItem}>Ethical production and fair labor practices</li>
            <li className={styles.valueItem}>Environmental responsibility through sustainable materials</li>
            <li className={styles.valueItem}>Innovation in design and craftsmanship</li>
            <li className={styles.valueItem}>Building a community of trust and sophistication</li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;