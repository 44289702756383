import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button, Alert, Pagination, Spin, Typography, message } from 'antd';
import { Link } from 'react-router-dom';
import { ShoppingCartOutlined, HeartOutlined } from '@ant-design/icons';
import AOS from 'aos'; // Import AOS library
import 'aos/dist/aos.css'; // Import AOS CSS for animations
import styles from '../bestsellers/products.module.css'
import { useLocation, useParams } from 'react-router-dom';
import { API_URL } from '../../contsants';

const { Meta } = Card;
const { Title, Text } = Typography;

const ProductList = ({ productId, initialIsWishlisted = false }) => {
  const [isWishlisted, setIsWishlisted] = useState(initialIsWishlisted);

  const [product, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const pageSize = 16;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('search');
  const { id } = useParams();


  React.useEffect(() => {
    AOS.init({ duration: 1000, once: false }); // Adjust duration and other options as needed
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true); // Set loading to true before fetching new products
      try {
        const response = await axios.get(`${API_URL}/companies/searched-products/?search=${searchQuery}&company=1`);
        setProducts(response.data.results);
        setTotalProducts(response.data.results.length);
        setLoading(false);
        setCurrentPage(1); // Reset to the first page when a new search is performed
      } catch (error) {
        console.error('Error fetching products:', error);
        setError(error.message);
        setLoading(false);
      }
    };
    

    if (searchQuery) {
      fetchProducts();
    }
  }, [searchQuery], [id]); // Add searchQuery as a dependency to refetch when it changes

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleWishlistClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      console.log('id', product[0].id)
      const response = await axios.post(`${API_URL}/companies/wishlist-items/`, {
        product: product[0].id
      });
      
      if (response.status === 200) {
        setIsWishlisted(!isWishlisted);
        console.log('Product added to wishlist successfully');
        // You can add additional logic here, such as updating the UI or showing a notification
      }
    } catch (error) {
      console.error('Error adding product to wishlist:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  if (loading) {
    return (
      <div className={styles.loadingcontainer}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" />;
  }

  // Calculate the products to display on the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentProducts = product.slice(startIndex, endIndex);

  return (
    <div className={styles.productlistcontainer}>
      <div style={{ marginBottom: '100px' }} data-aos="fade-up">
        {/* You can uncomment and use BestSellers if needed */}
        {/* <BestSellers /> */}
      </div>
      <div data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-delay="600">
        <Title level={3} className={styles.productlisttitle} style={{ color: '#172c47', fontFamily: 'Garamound', fontWeight: '400' }}>
          Showing Results for "{searchQuery}"
        </Title>
      </div>
      {product.length === 0 ? (
        <Alert message="No products found" type="info" />
      ) : (
        <>
          <div
            className={styles.productgrid}
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-delay="600"
          >
            {currentProducts.map((product) => (
              <Link to={`/product/${product.id}`} key={product.id} className={styles.productcardwrapper}>
                <Card
                  hoverable
                  className={styles.productcard}
                  cover={
                    <div className={styles.productimagecontainer}>
                      <img alt={product.name} src={product.image[0].image} className={styles.productimage} />
                      <div className={styles.productquickactions} 
                            onClick={handleWishlistClick} 
                            style={{ cursor: 'pointer' }}>
                          {isWishlisted ? (
                      
                            <HeartOutlined className={styles.actionicon} style={{ color: 'red', fontSize: '24px' }}/>
                          ) : (
                            <HeartOutlined  className={styles.actionicon} style={{ fontSize: '24px' }} />
                          )}
                      </div>
                    </div>
                  }
                >
                  <Meta
                    title={<Text strong className={styles.productname}>{product.name}</Text>}
                    description={
                      <div className={styles.productdetails}>
                        <Text className={styles.productprice}>${product.price.toFixed(2)}</Text>
                        <Text className={styles.productfit}>Fit: {product.fit}</Text>
                      </div>
                    }
                  />
                </Card>
              </Link>
            ))}
          </div>
          <Pagination
            className="custom-pagination"
            current={currentPage}
            total={totalProducts}
            pageSize={pageSize}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </>
      )}
      <div style={{ marginTop: '100px' }}></div>
    </div>
  );
};

export default ProductList;
