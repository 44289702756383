import React from 'react';
import { FacebookFilled, InstagramFilled, XOutlined } from '@ant-design/icons';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p>203, Barley lane Chadwell Heath Romford</p>
          <p><a href="mailto:contact@ottoackerman.com">contact@ottoackerman.com</a></p>
        </div>
        <div className="footer-section">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com/profile.php?id=61560296017629" target="_blank" rel="noopener noreferrer">
              <FacebookFilled />
            </a>
            <a href="https://x.com/Ottoackerman" target="_blank" rel="noopener noreferrer">
              <XOutlined />
            </a>
            <a href="https://www.instagram.com/otto_ackerman_london?igsh=N2VsMGx0bjJidWI5&utm_source=qr" target="_blank" rel="noopener noreferrer">
              <InstagramFilled />
            </a>
          </div>
        </div>
        <div className="footer-section">
          <h3>Legal</h3>
          <ul className="legal-links">
            <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy;  OTTO ACKERMAN</p>
        <p>{new Date().getFullYear()}</p>
      </div>
    </footer>
  );
};

export default Footer;
