import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { API_URL } from '../../contsants';

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const OfferContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1400px;
  max-height: 400px;
  width: 95%;
  margin: 5rem auto;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.2);

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%);
    transform: rotate(30deg);
  }
`;

const OfferImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
`;

const OfferImage = styled.img`
  width: 120%;
  height: 120%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: saturate(1.2) contrast(1.1);
  transition: transform 0.5s ease;

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }
`;

const OfferContent = styled.div`
  padding: 4rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

const OfferTitle = styled.h2`
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  line-height: 1.2;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
`;

const OfferDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 2.5rem;
  opacity: 0.9;
`;

const OfferPrice = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  animation: ${float} 3s ease-in-out infinite;
`;

const BuyButton = styled.button`
  background-color: white;
  color: #764ba2;
  border: none;
  padding: 1.2rem 2.5rem;
  border-radius: 50px;
  font-size: 1.3rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  align-self: flex-start;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-5px) scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  }
`;

const OfferDisplay = () => {
  const [offer, setOffer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchOffer();
  }, []);

  const fetchOffer = async () => {
    try {
      const response = await fetch(`${API_URL}/companies/retrieve-offers/1`);
      if (!response.ok) {
        throw new Error('Failed to fetch offer');
      }
      const data = await response.json();
      setOffer(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const formatPrice = (price) => {
    const numPrice = parseFloat(price);
    return isNaN(numPrice) ? price : `$${numPrice.toFixed(2)}`;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!offer) return <div>No offer available</div>;

  return (
    <OfferContainer>
      <OfferImageContainer>
        <OfferImage src={offer.image} alt={offer.title} />
      </OfferImageContainer>
      <OfferContent>
        <OfferTitle>{offer.title}</OfferTitle>
        <OfferDescription>{offer.description}</OfferDescription>
        <OfferPrice>{formatPrice(offer.price)}</OfferPrice>
        {/* <BuyButton>Claim Offer</BuyButton> */}
      </OfferContent>
    </OfferContainer>
  );
};

export default OfferDisplay;