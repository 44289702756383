import React from 'react';
import { Link } from 'react-router-dom';

import OttoAckermanCarousel from './OttoAckermanCarousel';
import OttoAckermanFooter from './Footer';
import LatestCollection from './ProductsPage';
import CategoryCards from './category'
import Signature from './signature/products'
import BestSellers from './bestsellers/products'
import AboutUs from './about_us/AboutUs';
import Commitment from './about_us/Commitment'
import Offers from './offers/Offers'


const productStyle = {
  textAlign: 'center',
  fontFamily: 'Arial, san-serif',
  fontSize: 'x-large',
  marginTop: '20px',
  color: '#242424',
  fontWeight: 'bolder'
};
const BaseStyle = {
  background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)'

}


const OttoAckerman = () => {
  return (
  <div style={BaseStyle}>
      {/* <div style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}>
        <OttoAckermanNavBar />
      </div> */}
      <div style={{ paddingTop: '0px' }}>
        <OttoAckermanCarousel />
        <CategoryCards />
        <AboutUs />
        <Signature />
        {/* <Offers /> */}
        <BestSellers />
        <LatestCollection />
        <Commitment />
        {/* <OttoAckermanFooter /> */}
      </div>
    </div>
  );
};

export default OttoAckerman;
