import React from 'react';
import { Card, Typography } from 'antd';
import CasualsImg from '../company_images/businessman.jpeg';

const { Title, Text } = Typography;

const ProductCard = ({ product }) => {
  const cardStyle = {
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    borderRadius: '0',
    overflow: 'hidden',
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    
    // background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)'

  };

  const contentStyle = {
    display: 'flex',
    width: '100%',
  };

  const imageContainerStyle = {
    flex: '1',
    overflow: 'hidden',
  };

  const productImageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const textContentStyle = {
    flex: '1',
    padding: '32px',
    textAlign: 'center',
    fontFamily: '"Poppins", sans-serif', // Change font here
  };

  const titleStyle = {
    fontFamily: 'Garamond', // Change font here
    marginBottom: '40px',
    fontWeight: '400',
    fontSize: '50px',
    fontOpticalSizing: 'auto',
    color: '#172c47'
  };

  const textStyle = {
    fontSize: '18px',
    lineHeight: '1.5',
    fontFamily: "EB Garamond",
    color: '#172c47'


  };

  return (

    <Card style={cardStyle} hoverable={false}>
      <div style={contentStyle}>
        <div style={textContentStyle}>
          <Title level={1} style={titleStyle}>T-SHIRTS</Title>
          <Text style={textStyle}>
            Our collection of T-shirts for men is designed to elevate your outfit with easy-going elegance. Embodying a relaxed feeling, our casual shirts offer a fresh impression that always looks good. With multiple options to suit your personality and style, our casual shirts are carefully crafted using the finest fabrics, including cotton, linen, silk, and lyocell. Designed for the perfect fit, our casual shirts feel like an extension of yourself. Discover a classic white royal oxford shirt or a floral silk twill shirt as your next fashion investment. Versatile and stylish, our casual shirts are ideal for events with a dress code that isn't too formal and they can still make you look impeccably dressed. For a sophisticated yet relaxed look, consider pairing your outfit with a colorful scarf to complete the ensemble. Experience the effortless elegance of our assorted casual shirts for men. Shop now and elevate your wardrobe with our premium collection that strikes the perfect balance between style and comfort.
          </Text>
        </div>
        <div style={imageContainerStyle}>
          <img src={CasualsImg} alt='img' style={productImageStyle} />
        </div>
      </div>
    </Card>
  );
};

export default ProductCard;
