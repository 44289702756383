import React from 'react';
import { Carousel } from 'antd';
// import carousel01 from "./images/carousel/boy.jpg";
// import carousel02 from "../company_images/images/carousel/covid.jpg";
// import carousel03 from "../company_images/images/carousel/shopping.jpg";
import carousel04 from "./company_images/images/carousel/cover.jpg";
// import overlayImage01 from "./images/overlay/image01.png"; // Import overlay images
// import overlayImage02 from "./images/overlay/image02.png";
// import overlayImage03 from "./images/overlay/image03.png";
import logo from "./company_images/images/ottoackermancaps.png";
import NavBar from './navbar/Navbar'


const contentStyle = {
  height: '850px',
  width: "100%",
  position: 'relative', // Ensure the parent container is relative for absolute positioning
  textAlign: 'center',
  objectFit: 'cover'
};

const overlayImageStyle = {
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, -90%)',
  zIndex: '10', // Ensure image is above other content
  maxHeight: "100px",
  width: "auto",
  maxWidth: "300px",
  objectFit: "contain"
};

const overlayTextStyle = {
  position: 'absolute',
  top: '10%',
  left: '50%',
  fontFamily: 'ui-san-serif',
  transform: 'translate(-250%, 1000%)',
  zIndex: '10', // Ensure image is above other content
};

const overlayText1Style = {
  position: 'absolute',
  top: '10%',
  left: '50%',
  fontFamily: 'ui-san-serif',
  fontSize: '4rem',
  fontWeight: 'bold',
  transform: 'translate(-50%, 100%)',
  zIndex: '10', 

};

const App = () => (
  <Carousel>
    {/* <div>
      <img src={carousel01} alt="Carousel 1" style={contentStyle} />
      <img src={overlayImage01} alt="Overlay 1" style={overlayImageStyle} />
    </div>
    <div>
      <img src={carousel02} alt="Carousel 2" style={contentStyle} />
      <img src={overlayImage02} alt="Overlay 2" style={overlayImageStyle} />
    </div>
    <div>
      <img src={carousel03} alt="Carousel 3" style={contentStyle} />
      <img src={overlayImage03} alt="Overlay 3" style={overlayImageStyle} />
    </div> */}
    <div>
    {/* <NavBar/> */}
      <img src={carousel04} alt="Carousel 4" style={contentStyle} />
      {/* <img src={logo} alt="Overlay 4" style={overlayImageStyle} /> */}
      {/* <h1 style={overlayTextStyle}>Upto 50% off on all products</h1> */}
      {/* <h3 style={overlayText1Style}>on all products</h3> */}

    </div>
  </Carousel>
);

export default App;
