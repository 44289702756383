import './App.css';
import OttoAckerman from './components/OttoAckerman'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CasualPage from './components/casuals'
import FormalPage from './components/category/tshirts'
import ProductDetails from './components/ProductDetails';
import Tshirts from './components/category/tshirts'
import SignUp from './components/sign_up/SignUp'
import SignIn from './components/sign_up/SignIn'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OtpVerification from './components/sign_up/OtpVerification';
import ProtectedRoute from './components/sign_up/ProtectedRoute';
import { AuthProvider } from './components/sign_up/AuthContext';
import UserProfile from './components/profile/UserProfile';
import SearchResults from './components/navbar/SearchResults';
import Navbar from './components/navbar/Navbar';
import ForgotPassword from './components/sign_up/ForgotPassword'
import Footer from './components/Footer'
import Cart from './components/cart/cart'
import WishList from './components/wishlist/wishlist'


function App() {
  return (
    <AuthProvider>
    <BrowserRouter>
  
    <Navbar />

      <Routes>
      <Route path="/casuals" element={<CasualPage />} />
      <Route path="/formals" element={<FormalPage />} />
      <Route path="/tshirts" element={<Tshirts />} />
      <Route path="/product/:id" element={<ProductDetails />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/verify-otp" element={<OtpVerification />} />
      <Route path="/search-results" element={<SearchResults />} />
      <Route path="/reset-password/:uid/:token" element={<ForgotPassword />} />
      <Route path="/" element={<OttoAckerman />} />

      <Route element={<ProtectedRoute/>}>
          <Route path="/profile" element={<UserProfile />} />   
          <Route path='/cart' element={<Cart />} />
          <Route path='/wishlist' element={<WishList />} />

      </Route>
      
 </Routes>
 <Footer />
 <ToastContainer />
 </BrowserRouter> 
 </AuthProvider>
  );
}

export default App;
