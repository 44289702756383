import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Navitem.css';
import { API_URL } from '../../contsants';

const NavItem = ({ icon, placeholder, isSearch }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const searchRef = useRef(null);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleClick = () => {
    if (isSearch) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;

    try {
      // You can either perform the search request here or navigate directly
      const response = await axios.get(`${API_URL}/companies/searched-products/?search=${searchQuery}&company=1`);
      console.log('Search results:', response.data.results);

      // Navigate to the search results page and pass the search query
      navigate(`/search-results?search=${searchQuery}`, { replace: true });
      setSearchQuery('');
      setIsExpanded(false); 
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  
    return (
      <div ref={searchRef} className="nav-item">
        {isSearch && isExpanded ? (
          <form onSubmit={handleSubmit} className="search-form">
            <div className="search-container">
              <input
                type="text"
                className="search-bar"
                placeholder={placeholder}
                value={searchQuery}
                onChange={handleInputChange}
                autoFocus
              />
              <button type="submit" className="search-icon">
                {icon}
              </button>
            </div>
          </form>
        ) : (
          <div className="icon" onClick={handleClick}>
            {icon}
          </div>
        )}
      </div>
    );
  };
export default NavItem;