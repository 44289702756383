import { toast } from 'react-toastify';
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import OttoLogo from '../company_images/images/logoottoackerman.png'
import { API_URL } from '../../contsants';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const SignupContainer = styled.div`
  display: flex;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
`;

const LeftPanel = styled.div`
  flex: 1;
  background: linear-gradient(-45deg, #172c47, #1a3559, #21466b, #172c47);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 2rem;
  position: relative;
  overflow: hidden;
`;

const Quote = styled.h2`
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  margin-bottom: 2rem;
  z-index: 1;
`;

const Vector = styled.div`
  position: absolute;
  opacity: 0.1;
  font-size: 15rem;
  z-index: 0;
`;

const RightPanel = styled.div`
  flex: 1;
  background-color: #f8fafc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const SignupForm = styled.form`
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  color: #172c47;
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 2.5rem;
  text-align: center;
`;

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 2rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border: none;
  border-bottom: 2px solid #e0e6ed;
  background-color: transparent;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #172c47;
  }
`;

const Label = styled.label`
  position: absolute;
  left: 0;
  top: 1rem;
  color: #8194a7;
  transition: all 0.3s ease;
  pointer-events: none;

  ${Input}:focus ~ &,
  ${Input}:not(:placeholder-shown) ~ & {
    top: -1rem;
    font-size: 0.8rem;
    color: #172c47;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  background-color: #172c47;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(23, 44, 71, 0.1);

  &:hover {
    background-color: #2a4a77;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(23, 44, 71, 0.2);
  }
`;

const SignUpLink = styled.p`
  text-align: center;
  margin-top: 2rem;
  color: #8194a7;

  a {
    color: #172c47;
    text-decoration: none;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SignUp = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });

const handleChange = (event) => {
  const { name, value } = event.target;
  setFormData((prevFormData) => ({
    ...prevFormData,
    [name]: value,
  }));
};

const handleSubmit = async (event) => {
    event.preventDefault();
  
    const postData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      password: formData.password,
    };
  
    try {
      const response = await fetch(`${API_URL}/users/sign-up/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        toast.success("An OTP has been sent to your email address. Please enter the OTP to verify your account.", {
          position: "top-center",
          autoClose: 2000,
          onClose: () => navigate(`/verify-otp?email=${encodeURIComponent(formData.email)}`)
        });
      }
      else {
        if (data) {
            const errorMessages = [];
            
            Object.keys(data).forEach(key => {
              if (Array.isArray(data[key])) {
                data[key].forEach(message => {
                  errorMessages.push(`${key}: ${message}`);
                });
              }
            });
    
            if (errorMessages.length > 0) {
              errorMessages.forEach(message => {
                toast.error(message, {
                  position: "top-center",
                  autoClose: 5000,
                });
              });
            } else {
              // If no specific errors found, show a generic error
              toast.error("An error occurred during sign up. Please try again.", {
                position: "top-center",
                autoClose: 3000,
              });
            }
          } else {
            // If no data in response, show a generic error
            toast.error("An error occurred during sign up. Please try again.", {
              position: "top-center",
              autoClose: 3000,
            });
          }
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error("An unexpected error occurred. Please try again.", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    };
        
  

  return (
    <SignupContainer>
      <LeftPanel>
        <Quote>"Become a member and elevate your style with every piece."</Quote>
        <Vector style={{ top: '10%', left: '10%' }}>◢</Vector>
        <Vector style={{ bottom: '10%', right: '10%' }}>◣</Vector>
      </LeftPanel>
      <RightPanel>
        <SignupForm onSubmit={handleSubmit}>
          <Title>Join Us</Title>
          <InputGroup>
            <Input
              type="text"
              name="first_name"
              id="first_name"
              placeholder=" "
              value={formData.first_name}
              onChange={handleChange}
              required
            />
            <Label htmlFor="first_name">First Name</Label>
          </InputGroup>
          <InputGroup>
            <Input
              type="text"
              name="last_name"
              id="last_name"
              placeholder=" "
              value={formData.last_name}
              onChange={handleChange}
              required
            />
            <Label htmlFor="last_name">Last Name</Label>
          </InputGroup>
          <InputGroup>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder=" "
              value={formData.email}
              onChange={handleChange}
              required
            />
            <Label htmlFor="email">Email</Label>
          </InputGroup>
          <InputGroup>
            <Input
              type="password"
              name="password"
              id="password"
              placeholder=" "
              value={formData.password}
              onChange={handleChange}
              required
            />
            <Label htmlFor="password">Password</Label>
          </InputGroup>
          <Button type="submit">Sign Up</Button>
          <SignUpLink> Already have an account? <Link to="/sign-in"> Sign In</Link>
          </SignUpLink>
        </SignupForm>
      </RightPanel>
    </SignupContainer>
  );
};

export default SignUp;
