import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import { useAuth } from './AuthContext'; 
import OttoLogo from '../company_images/images/logoottoackerman.png'
import { API_URL } from '../../contsants';

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const SigninContainer = styled.div`
  display: flex;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
`;

const LeftPanel = styled.div`
  flex: 1;
  background: linear-gradient(-45deg, #172c47, #1a3559, #21466b, #172c47);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 2rem;
  position: relative;
  overflow: hidden;
`;

const Quote = styled.h2`
  font-size: 2rem;
  font-weight: 300;
  text-align: center;
  margin-bottom: 2rem;
  z-index: 1;
`;

const Vector = styled.div`
  position: absolute;
  opacity: 0.1;
  font-size: 15rem;
  z-index: 0;
`;

const RightPanel = styled.div`
  flex: 1;
  background-color: #f8fafc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const SigninForm = styled.form`
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  color: #172c47;
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 2.5rem;
  text-align: center;
`;

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 2rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border: none;
  border-bottom: 2px solid #e0e6ed;
  background-color: transparent;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: #172c47;
  }
`;

const Label = styled.label`
  position: absolute;
  left: 0;
  top: 1rem;
  color: #8194a7;
  transition: all 0.3s ease;
  pointer-events: none;

  ${Input}:focus ~ &,
  ${Input}:not(:placeholder-shown) ~ & {
    top: -1rem;
    font-size: 0.8rem;
    color: #172c47;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  background-color: #172c47;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(23, 44, 71, 0.1);

  &:hover {
    background-color: #2a4a77;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(23, 44, 71, 0.2);
  }
`;

const ForgotPassword = styled.a`
  display: block;
  text-align: right;
  color: #172c47;
  font-size: 0.9rem;
  margin-top: 1rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const SignUpLink = styled.p`
  text-align: center;
  margin-top: 2rem;
  color: #8194a7;

  a {
    color: #172c47;
    text-decoration: none;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Error = styled.p`
  color: red;
  text-align: center;
  margin-top: 1rem;
`;

const Success = styled.p`
  color: green;
  text-align: center;
  margin-top: 1rem;
`;

const LogoStyle= {
  position: 'absolute',
  left: '75%',
  transform:' translateX(-50%)',
  maxHeight: '80px',
  width: 'auto',
  display: 'block',
  transition: 'all 0.3s ease',
  top: '15%'

}

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  position: relative;  // Add this to position the close button
`;


const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #172c47;
  transition: color 0.3s ease;

  &:hover {
    color: #2a4a77;
  }
`;



const SignIn = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();
  const { login } = useAuth();

  // New state for forgot password popup
  const [showForgotPasswordPopup, setShowForgotPasswordPopup] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const success = await login(formData.email, formData.password);
      if (success) {
        setSuccess('Welcome back!');
        setError(null);
        navigate('/'); // Redirect to home page or dashboard
      } else {
        setError('Invalid email or password.');
        setSuccess(null);
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
      setSuccess(null);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  // New function to handle forgot password
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResetMessage(''); // Clear any previous messages

    try {
      const response = await axios.post(`${API_URL}/users/password-reset/`, { email: resetEmail });
      setResetMessage('Password reset link sent to your email.');
      setIsLoading(false);
      
      // Keep the popup open for 3 seconds to show the success message
      setTimeout(() => {
        setShowForgotPasswordPopup(false);
        setResetEmail('');
        setResetMessage('');
      }, 3000);
    } catch (error) {
      setResetMessage('Error: Unable to process your request.');
      setIsLoading(false);
    }
  };

  return (
    <SigninContainer>
      <LeftPanel>
        <Quote>"Elegance is the only beauty that never fades."</Quote>
        <Vector style={{ top: '10%', left: '10%' }}>◢</Vector>
        <Vector style={{ bottom: '10%', right: '10%' }}>◣</Vector>
      </LeftPanel>
      <RightPanel>
        <SigninForm onSubmit={handleSubmit}>
          <img src={OttoLogo} alt='logo' style={LogoStyle}/>
          <Title>Welcome Back</Title>
          {error && <Error>{error}</Error>}
          {success && <Success>{success}</Success>}
          <InputGroup>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder=" "
              value={formData.email}
              onChange={handleChange}
              required
            />
            <Label htmlFor="email">Email</Label>
          </InputGroup>
          <InputGroup>
            <Input
              type="password"
              name="password"
              id="password"
              placeholder=" "
              value={formData.password}
              onChange={handleChange}
              required
            />
            <Label htmlFor="password">Password</Label>
          </InputGroup>
          <Button type="submit">Sign In</Button>
          <ForgotPassword onClick={() => setShowForgotPasswordPopup(true)}>Forgot Password?</ForgotPassword>
          <SignUpLink> Don't have an account? <Link to="/sign-up"> Sign Up</Link></SignUpLink>
        </SigninForm>
      </RightPanel>

      {showForgotPasswordPopup && (
      <PopupOverlay>
        <PopupContent>
          <CloseButton onClick={() => setShowForgotPasswordPopup(false)}>&times;</CloseButton>
          <Title>Reset Password</Title>
          <form onSubmit={handleForgotPassword}>
            <InputGroup>
              <Input
                type="email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                placeholder=" "
                required
              />
              <Label>Enter your email</Label>
            </InputGroup>
            <Button type="submit" disabled={isLoading}>
              {isLoading ? 'Sending...' : 'Submit'}
            </Button>
          </form>
          {resetMessage && <p>{resetMessage}</p>}
        </PopupContent>
      </PopupOverlay>
    )}
    </SigninContainer>
  );
};

export default SignIn;