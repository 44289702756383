import React, { useState } from 'react';
import { Card, Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos'; // Import AOS library
import 'aos/dist/aos.css'; // Import AOS CSS for animations
import category1 from './company_images/images/category4.jpg';
import category2 from './company_images/images/category5.jpg';
import category3 from './company_images/images/category6.jpg';
import casualsPage from './casuals';
import formalsPage from './category/tshirts';

const { Text, Title } = Typography;

const { Meta } = Card;

const cardStyle = {
  width: '300px',
  borderRadius: '10px',
  overflow: 'hidden',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
  marginBottom: '20px'
};

const overlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '400px', // Adjusted height to cover entire card
  backgroundColor: 'rgba(0, 0, 0, 0.4)', // Adjust the opacity as needed (0.5 for 50% transparent white)
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  zIndex: 1, // Ensure overlay is above the image
  color: '#ffffff',
  padding: '20px', // Add padding to the overlay content
  boxSizing: 'border-box', // Ensure padding doesn't affect width
  textAlign: 'center', // Center-align content within the overlay
};

const textStyle = {
  color: '#ffffff', // Adjust text color as needed
  fontSize: '20px',
  fontWeight: 'bold',
  fontFamily: 'Garamound',
  marginBottom: '10px', // Add margin bottom to separate text from button
  maxWidth: '80%', // Limit text width to 80% of the overlay width
  wordWrap: 'break-word', // Allow words to break and wrap to the next line
  overflowWrap: 'break-word',
  marginTop: '200px',
  alignItems: 'left',
};

const buttonStyle = {
  backgroundColor: '#ffffff', // Initial background color
  color: '#172c47', // Initial text color
  border: 'none',
  padding: '10px 20px',
  fontSize: '16px',
  borderRadius: '0', // No border radius
  cursor: 'pointer',
  transition: 'background-color 0.3s ease', // Smooth transition
  textDecoration: 'none',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Button shadow
};

const categoryTitleStyle = {
  textAlign: 'center',
  marginBottom: '60px',
  fontSize: '36px',
  color: '#172c47',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  paddingBottom: '50px',
};

const App = () => {
  const navigate = useNavigate();
  // Initialize AOS when the component mounts
  React.useEffect(() => {
    AOS.init({ duration: 1000, once: false }); // Adjust duration and other options as needed
  }, []);

  // Hover state variables for each button
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      <Title level={2} style={{ color: '#172c47', letterSpacing: '2px', fontFamily: 'Garamond', fontWeight: '400' }}>CATEGORIES</Title>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 40,
          marginTop: '20px',
          flexWrap: 'wrap', // Add flexWrap to make the layout responsive
        }}
      >
        <Card
          hoverable
          style={cardStyle}
          cover={
            <div style={{ position: 'relative', height: '350px' }}>
              <img alt="example" src={category1} style={{ width: '100%', height: 'auto' }} />
              <div style={overlayStyle}>
                <div style={textStyle}>
                  <Text level={2} style={{ color: '#efcf96', letterSpacing: '2px', fontFamily: 'Garamond' }}>BUSINESS CASUALS</Text>
                </div>
                <Button
                  style={{ ...buttonStyle, backgroundColor: isHovered1 ? '#172c47' : '#ffffff', color: isHovered1 ? '#ffffff' : '#172c47' }}
                  onMouseEnter={() => setIsHovered1(true)}
                  onMouseLeave={() => setIsHovered1(false)}
                  onClick={() => navigate('/casuals')}
                >
                  Shop Now
                </Button>
              </div>
            </div>
          }
          data-aos="fade-up" // AOS animation effect
          data-aos-delay="0"
        ></Card>
        <Card
          hoverable
          style={cardStyle}
          cover={
            <div style={{ position: 'relative', height: '350px' }}>
              <img alt="example" src={category2} style={{ width: '100%', height: 'auto' }} />
              <div style={overlayStyle}>
                <div style={textStyle}>
                  <Text level={2} style={{ color: '#efcf96', letterSpacing: '2px', fontFamily: 'Garamond' }}>CASUALS</Text>
                </div>
                <Button
                  style={{ ...buttonStyle, backgroundColor: isHovered2 ? '#172c47' : '#ffffff', color: isHovered2 ? '#ffffff' : '#172c47' }}
                  onMouseEnter={() => setIsHovered2(true)}
                  onMouseLeave={() => setIsHovered2(false)}
                  onClick={() => navigate('/casuals')}
                >
                  Shop Now
                </Button>
              </div>
            </div>
          }
          data-aos="fade-up" // AOS animation effect
          data-aos-delay="200"
        ></Card>
        <Card
          hoverable
          style={cardStyle}
          cover={
            <div style={{ position: 'relative', height: '350px' }}>
              <img alt="example" src={category3} style={{ width: '100%', height: 'auto' }} />
              <div style={overlayStyle}>
                <div style={textStyle}><Text level={2} style={{ color: '#efcf96', letterSpacing: '2px', fontFamily: 'Garamond' }}>FORMAL SHIRTS</Text>
                </div>
                <Button
                  style={{ ...buttonStyle, backgroundColor: isHovered3 ? '#172c47' : '#ffffff', color: isHovered3 ? '#ffffff' : '#172c47' }}
                  onMouseEnter={() => setIsHovered3(true)}
                  onMouseLeave={() => setIsHovered3(false)}
                  onClick={() => navigate('/formals')}
                >
                  Shop Now
                </Button>
              </div>
            </div>
          }
          data-aos="fade-up" // AOS animation effect
          data-aos-delay="400"
        ></Card>
        <Card
          hoverable
          style={cardStyle}
          cover={
            <div style={{ position: 'relative', height: '350px' }}>
              <img alt="example" src={category1} style={{ width: '100%', height: 'auto' }} />
              <div style={overlayStyle}>
                <div style={textStyle}>
                  <Text level={2} style={{ color: '#efcf96', letterSpacing: '2px', fontFamily: 'Garamond' }}>T-SHIRTS</Text>
                </div>
                <Button
                  style={{ ...buttonStyle, backgroundColor: isHovered4 ? '#172c47' : '#ffffff', color: isHovered4 ? '#ffffff' : '#172c47' }}
                  onMouseEnter={() => setIsHovered4(true)}
                  onMouseLeave={() => setIsHovered4(false)}
                  onClick={() => navigate('/tshirts')}
                >
                  Shop Now
                </Button>
              </div>
            </div>
          }
          data-aos="fade-up" // AOS animation effect
          data-aos-delay="600"
        ></Card>
      </div>
    </div>
  );
};

export default App;
