import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Button, Alert, Pagination, Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { ShoppingCartOutlined, HeartOutlined } from '@ant-design/icons';
import AOS from 'aos'; // Import AOS library
import 'aos/dist/aos.css'; // Import AOS CSS for animations
import styles from './ProductsCard.module.css'
import { API_URL } from '../contsants';

const { Meta } = Card;
const { Title, Text } = Typography;

const ProductList = ({ productId, initialIsWishlisted = false }) => {
  const [isWishlisted, setIsWishlisted] = useState(initialIsWishlisted);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0);
  const pageSize = 4;
  
  React.useEffect(() => {
    AOS.init({ duration: 1000, once: false }); // Adjust duration and other options as needed
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${API_URL}/companies/latest-products/`);
        setProducts(response.data.results);
        setTotalProducts(response.data.results.length);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleWishlistClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      console.log('id', products[0].id)
      const response = await axios.post(`${API_URL}/companies/wishlist-items/`, {
        product: products[0].id
      });
      
      if (response.status === 200) {
        setIsWishlisted(!isWishlisted);
        console.log('Product added to wishlist successfully');
        // You can add additional logic here, such as updating the UI or showing a notification
      }
    } catch (error) {
      console.error('Error adding product to wishlist:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return (
      <div className={styles.loadingcontainer}>
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  if (error) {
    return <Alert message="Error" description={error} type="error" />;
  }

  // Calculate the products to display on the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentProducts = products.slice(startIndex, endIndex);

return (
  <div className={styles.productlistcontainer}>
<div data-aos="fade-up"
     data-aos-anchor-placement="top-bottom" data-aos-delay="600">
  <Title level={2} className={styles.productlisttitle} style={{color:' #172c47', fontFamily: 'Garamound', fontWeight: '400'}}>Our Latest Collections</Title>
  </div>
  {products.length === 0 ? (
    <Alert message="No products found" type="info" />
  ) : (
    <>
      <div className={styles.productgrid}
       data-aos="fade-up"
       data-aos-anchor-placement="top-bottom"
       data-aos-delay="600">
        {currentProducts.map((product) => (
          <Link to={`/product/${product.id}`} key={product.id} className={styles.productcardwrapper}>
            <Card
              hoverable
              className={styles.productcard}
              cover={
                <div className={styles.productimagecontainer}>
                  <img alt={product.name} src={product.image[0].image} className={styles.productimage} />
                  <div className={styles.productquickactions} 
                        onClick={handleWishlistClick} 
                        style={{ cursor: 'pointer' }}>
                          {isWishlisted ? (
                      
                            <HeartOutlined className={styles.actionicon} style={{ color: 'red', fontSize: '24px' }}/>
                          ) : (
                            <HeartOutlined  className={styles.actionicon} style={{ fontSize: '24px' }} />
                          )}
                      </div>

                </div>
              }
            >
              <Meta
                title={<Text strong className={styles.productname}>{product.name}</Text>}
                description={
                  <div className={styles.productdetails}>
                    <Text className={styles.productprice}>${product.price.toFixed(2)}</Text>
                    <Text className={styles.productfit}>Fit: {product.fit}</Text>
                  </div>
                }
              />
            </Card>
          </Link>
        ))}
      </div>
      <Pagination
        className="custom-pagination"
        current={currentPage}
        total={totalProducts}
        pageSize={pageSize}
        onChange={handlePageChange}
        showSizeChanger={false}
      />
    </>
  )}
  <div style={{marginTop: '100px'}}>
  </div>
</div>
);
};

export default ProductList;
