import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';
import './Navbar.css';
import logo from '../company_images/images/logoottoackerman.png';
import NavItem from './NavItem';
import { FaSearch, FaUser, FaShoppingBag, FaHeart } from 'react-icons/fa';

const hideNavbarRoutes = [
  '/sign-in',
  '/sign-up',
  '/profile',
  '/reset-password/:uid/:token',
];

const showLogoOnlyRoutes = [
  '/checkout',
  '/contact',
  '/reset-password', 
];

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  // Function to check if the current path matches any pattern in hideNavbarRoutes
  const shouldHideNavbar = hideNavbarRoutes.some((route) =>
    matchPath(route, location.pathname) !== null
  );

  // Function to check if the current path should only show the logo
  const shouldShowLogoOnly = showLogoOnlyRoutes.some((route) =>
    matchPath(route, location.pathname) !== null
  );

  // Scroll event listener to determine if the user has scrolled past a certain point
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10); // Adjust scroll threshold as needed
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // If the entire navbar should be hidden, return null
  if (shouldHideNavbar) {
    return null;
  }

  // Navbar Component
return (
  <nav className={`navbar ${shouldShowLogoOnly ? 'logo-only' : ''} ${isScrolled ? 'scrolled' : ''}`}>
    <div className="navbar-content">
      <div className="navbar-logo" onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
        <img src={logo} alt="Logo"  />
      </div>
      {!shouldShowLogoOnly && (
        <div className="navbar-items">
          <NavItem
            icon={<FaSearch />}
            placeholder="Search Products"
            isSearch // Pass a prop to indicate that this is a search item
          />
          <div onClick={() => navigate('/profile')}>
            <NavItem icon={<FaUser />} />
          </div>
          <div onClick={() => navigate('/cart')}>
            <NavItem icon={<FaShoppingBag />} />
          </div>
          <div onClick={() => navigate('/wishlist')}>
            <NavItem icon={<FaHeart />} />
          </div>
        </div>
      )}
    </div>
  </nav>
);
};
export default Navbar;
