import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Trash2, ShoppingCart, AlertCircle, CheckCircle } from 'lucide-react';
import axios from 'axios';
import { API_URL } from '../../contsants';

const WishlistContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 80px;
  margin-bottom: 250px;

`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #2c3e50;
  text-align: center;
`;

const ItemList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 40px;
`;

const ItemCard = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  margin-top: 30px;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ItemImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ItemDetails = styled.div`
  padding: 16px;
`;

const ItemName = styled.h3`
  font-weight: 600;
  margin: 0 0 8px;
  color: #34495e;
`;

const ItemPrice = styled.p`
  color: #172c47;
  font-weight: bold;
  margin: 0 0 16px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconButton = styled.button`
  padding: 8px 16px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: background-color 0.3s ease;
  border-radius: 4px;
  
  &:hover {
    opacity: 0.8;
  }
`;

const AddToCartButton = styled(IconButton)`
  background-color: #172c47;
  color: #efcf96;

  &:hover {
    background-color: #efcf96;
    color: black;
  }
`;

const RemoveButton = styled(IconButton)`
  color: #e74c3c;
  
  &:hover {
    background-color: #fde8e8;
  }
`;

const EmptyMessage = styled.p`
  text-align: center;
  color: #7f8c8d;
  margin-top: 40px;
  font-size: 1.2rem;
`;

const Toast = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.3s ease;
  z-index: 1000;
  background-color: ${props => props.type === 'success' ? '#2ecc71' : '#e74c3c'};

`;

const WishlistItem = ({ item, onRemove, onAddToCart }) => (
  <ItemCard>
    <ItemImage src={item.image[0].image} alt={item.name} />
    <ItemDetails>
      <ItemName>{item.name}</ItemName>
      <ItemPrice>${item.price.toFixed(2)}</ItemPrice>
      <ButtonGroup>
        <AddToCartButton onClick={() => onAddToCart(item)}>
          <ShoppingCart size={20} />
          Add to Cart
        </AddToCartButton>
        <RemoveButton onClick={() => onRemove(item.id)}>
          <Trash2 size={20} />
        </RemoveButton>
      </ButtonGroup>
    </ItemDetails>
  </ItemCard>
);

const Wishlist = () => {
  const [wishlistItems, setWishlistItems] = useState([]);
  const [toast, setToast] = useState({ show: false, message: '', type: 'success' });

  useEffect(() => {
    fetchWishlistItems();
  }, []);

  const fetchWishlistItems = async () => {
    try {
      const response = await axios.get(`${API_URL}/companies/get-wishlist-items/`);
      setWishlistItems(response.data.results[0].products);
    } catch (error) {
      console.error('Error fetching wishlist items:', error);
      showToast('Failed to load wishlist items', 'error');
    }
  };

  const removeFromWishlist = async (id) => {
    try {
      await axios.post(`${API_URL}/companies/remove-wishlist-items/`, { product: id });
      setWishlistItems(wishlistItems.filter(item => item.id !== id));
      showToast('Item removed from wishlist', 'success');
    } catch (error) {
      console.error('Error removing item from wishlist:', error);
      showToast('Failed to remove item from wishlist', 'error');
    }
  };

  const addToCart = async (item) => {
    try {
      const defaultSize = item.size[0].id;
      await axios.post(`${API_URL}/companies/cart/`, {
        product: item.id,
        quantity: 1,
        size_id: defaultSize
      });
      showToast(`${item.name} added to cart`, 'success');
    } catch (error) {
      console.error('Error adding item to cart:', error);
      showToast('Failed to add item to cart', 'error');
    }
  };

  const showToast = (message, type) => {
    setToast({ show: true, message, type });
    setTimeout(() => setToast({ ...toast, show: false }), 3000);
  };

  return (
    <WishlistContainer>
      <Title>Your Wishlist</Title>
      <ItemList>
        {wishlistItems.map(item => (
          <WishlistItem 
            key={item.id} 
            item={item} 
            onRemove={removeFromWishlist}
            onAddToCart={addToCart}
          />
        ))}
      </ItemList>
      {wishlistItems.length === 0 && (
        <EmptyMessage>Your wishlist is empty</EmptyMessage>
      )}
      <Toast show={toast.show} type={toast.type}>
        {toast.type === 'success' ? <CheckCircle size={20} /> : <AlertCircle size={20} />}
        {toast.message}
      </Toast>
    </WishlistContainer>
  );
};

export default Wishlist;