import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Navbar from '../navbar/Navbar';
import { API_URL } from '../../contsants';

const OtpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8fafc;
  font-family: 'Poppins', sans-serif;
`;

const OtpForm = styled.form`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h2`
  color: #172c47;
  text-align: center;
  margin-bottom: 1rem;
`;

const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const OtpInput = styled.input`
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1.5rem;
  border: 2px solid #e0e6ed;
  border-radius: 4px;
  margin: 0 0.25rem;

  &:focus {
    outline: none;
    border-color: #172c47;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #172c47;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2a4a77;
  }
`;

const ResendLink = styled.a`
  display: block;
  text-align: center;
  margin-top: 1rem;
  color: #172c47;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const OtpVerification = () => {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(emailParam);
    } else {
      // Redirect to sign-up if email is not provided
      navigate('/sign-up');
    }
  }, [location, navigate]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join('');
    
    try {
      const response = await fetch(`${API_URL}/users/verify-otp/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp: otpValue }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success('OTP verified successfully!, Welcome to our community.');
        navigate('/sign-in'); // Redirect to dashboard or home page
      } else {
        toast.error(data.message || 'OTP verification failed');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An unexpected error occurred');
    }
  };

  const handleResendOtp = async () => {
    try {
      const response = await fetch(`${API_URL}/companies/resend-otp/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success('New OTP sent successfully');
      } else {
        toast.error(data.message || 'Failed to resend OTP');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An unexpected error occurred');
    }
  };

  return (
    <OtpContainer>
    <Navbar/>
      <OtpForm onSubmit={handleSubmit}>
        <Title>Enter Verification Code</Title>
        <InputGroup>
          {otp.map((data, index) => {
            return (
              <OtpInput
                type="text"
                name="otp"
                maxLength="1"
                key={index}
                value={data}
                onChange={e => handleChange(e.target, index)}
                onFocus={e => e.target.select()}
              />
            )
          })}
        </InputGroup>
        <SubmitButton type="submit">Verify OTP</SubmitButton>
        <ResendLink href="#" onClick={handleResendOtp}>Resend OTP</ResendLink>
      </OtpForm>
    </OtpContainer>
  );
};

export default OtpVerification;